@import "../../../sass/combinedStyles.scss";

.serviceCard {
    text-align: center;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    width: 275px;
    margin: 0 10px 10px 10px;
}

p{
    margin: 10px;
}

.serviceCard:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


@media (max-width: 925px){
    .serviceCard {
        width: 100%;
    }
}