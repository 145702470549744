@font-face {
    font-family: 'Cardo';
    src: url(../../assets/fonts/Cardo/Cardo-Regular.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cardo';
    src: url(../../assets/fonts/Cardo/Cardo-Bold.ttf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cardo';
    src: url(../../assets/fonts/Cardo/Cardo-Italic.ttf);
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'JosefinSans';
    src: url(../../assets/fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans';
    src: url(../../assets/fonts/Josefin_Sans/JosefinSans-Italic-VariableFont_wght.ttf);
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'JosefinSans';
    src: url(../../assets/fonts/Josefin_Sans/static/JosefinSans-Bold.ttf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans';
    src: url(../../assets/fonts/Josefin_Sans/static/JosefinSans-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'JosefinSans';
    src: url(../../assets/fonts/Josefin_Sans/static/JosefinSans-ExtraLight.ttf);
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans';
    src: url(../../assets/fonts/Josefin_Sans/static/JosefinSans-ExtraLightItalic.ttf);
    font-weight: lighter;
    font-style: italic;
}

$headingFont: Cardo, serif;
$bodyFont: JosefinSans, serif;