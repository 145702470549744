@import "../../../sass/combinedStyles.scss";

.formContainer {
  display: flex;
  width: 100%;
  position: relative;
  top: -00px;
}

form {
  font-family: $bodyFont;
  width: 50%;
  .labelBox,
  .inputBox,
  textarea,
  select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .radioTitle{
    padding: 0;
    margin: 0;
  }
  .radioItem {
    margin: 5px 5px;
    input{
      -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #999;
  transition: 0.2s all linear;
  margin-right: 5px;
    }
    input:checked {
      border: 6px solid black;
    }
  }
  
  .radioContainer {
    display: flex;
    
  }
  .radioField {
    width: 100%;
  }
}

.contactFormImage {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    width: 270px;
  }
}

.formField {
  input,
  select,
  textarea {
    background-color: rgba(224, 224, 224, 0.301);
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid gray;
    padding: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  select {
    background-image: url("../../../assets/images/downArrow.png"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-size: 10px;
    background-repeat: no-repeat, repeat;

    background-position: right 0.7em top 50%, 0 0;
  }
  input:focus,
  select:focus,
  textarea:focus {
    
    outline: none;
  }
  button {
    margin: 5px 0px;
    border-radius: 0px;
    border: none;
    padding: 10px 20px;
    background-color: #c29b6c;
    color: black;
    font-weight: bold;
    font-family: $bodyFont;
    font-size: 1.2em;
    cursor: pointer;
  }
}

.map {
  position: absolute;
  top: -95px;
  left: -20px;
  width: 100%;
  height: 200px;
  z-index: -1;
}

@media (max-width: 600px) {
  .formContainer {
    flex-direction: column;
  }
  form {
    width: 100%;
  }
  .contactFormImage {
    width: 100%;
    padding: 20px 10px;
  }
}
