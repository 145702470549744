@import "../../../sass/combinedStyles.scss";

.accordionHeader {
  background-color: $lightBlue;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordionHeader:after {
  content: "\02795";
  font-size: 13px;
  color: #777;
}

.active:after {
  content: "\2796";
}

.active,
.accordionHeader:hover {
  background-color: $lightGold;
}

.accordionBody {
  display: none;
  padding: 10px 18px;
  background-color: white;
  overflow: hidden;
  
}

.show {
    display: block;
    animation: reveal 0.2s;
    transform-origin: top center;
}

li {
  margin-left: 10px;
}

@keyframes reveal {
    from{transform: scaleY(0);}
    to{transform: scaleY(1);}
}


@media (max-width: 600px) {
}
